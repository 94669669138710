// Import necessary libraries
// import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import lottieAnimationLogin from "../../assets/Images/login.json";
import lottieAnimationSingUp from "../../assets/Images/sign-up.json";

const LottieAnimation = (value) => {
  let url;
  // console.log(value);
  if(value.value == "login"){
    url = lottieAnimationLogin
  }
  else if( value.value == "signup"){
    url = lottieAnimationSingUp
  }

  // console.log(url);
  return (
    <div className="overflow-hidden  ">
      <Player
        className="scale-125"
        autoplay
        loop
        src={url}
        // style={{ height: '500px', width: '700px' }}
      ></Player>
    </div>
  );
};

export default LottieAnimation;
