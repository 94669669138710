const Whatsapp = () => {
  return (
    <div className="text-white w-full h-screen flex flex-col justify-center items-center gap-6 p-4">
      <h1 className="text-2xl md:text-4xl font-bold text-center">
        Welcome to HTS Learn
      </h1>

      <a
        href="https://wa.me/919354043990?text=Hey%20can%20I%20get%20more%20info"
        alt="WhatsApp Link"
        target="_blank"
        rel="noopener noreferrer"
        className="px-6 py-3 rounded-xl text-lg md:text-xl hover:bg-[#25D366] bg-[#138f40] transition-colors duration-200"
      >
        Join WhatsApp
      </a>
    </div>
  );
};

export default Whatsapp;
