import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import bg from "../../../assets/Team/imgbg.png"

import { instructorDataArray } from "../../../data/InstructorData";
const TeamInfo = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500, // Set the transition speed to 500 milliseconds
    slidesToShow: 4, // Show 4 images on desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Set the autoplay delay to 2 seconds
    responsive: [
      {
        breakpoint: 1844,
        settings: {
          slidesToShow: 1, // Show 3 images on tablet
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 image on mobile
        },
      },
    ],
  };

  return (
    <section className="py-8 bg-gray-200 text-gray-600 lg:w-[1024px] m-auto  ">
      <div className="mb-10">
        <h5 className="text-3xl text-white font-extrabold mb-8 text-center text-gray-600">
          Our Team
        </h5>
        <Slider {...settings}>
          {instructorDataArray.map((p, index) => (
            <div
              key={index}
              className="relative rounded-3xl p-2 lg:p-0  h-full lg:h-[50vh] flex justify-center items-center m-auto "
            >
              <div className="h-[80vh] lg:h-full lg:px-10 w-[100%] lg:w-[100%] bg-white flex-col lg:flex-row lg:flex   justify-between items-center m-auto rounded-3xl overflow-hidden">
                <div className="lg:w-[30%]  lg:left-4 relative flex align-bottom items-center justify-center lg:h-full h-[40%] z-20 ">
                  <img
                    src={p.src}
                    alt={`Instructor ${index + 1}`}
                    className="  bottom-0 absolute w-[60%] lg:w-full  "
                  />
                  <img
                    src="https://res.cloudinary.com/defdiimmj/image/upload/v1721650784/Hts/dbbktfi2mv9limc9d1sd.png"
                    className="absolute -z-10 lg:scale-150 bottom-0   "
                  />
                </div>
                <div className="lg:w-[60%]   w-[100%] flex flex-col items-center text-center p-5 lg:pl-20">
                  <h1 className="text-xl font-bold mt-2">{p.name}</h1>
                  <h2 className="text-lg">{p.designation}</h2>
                  <h2 className="text-[13px] mt-2 font-[100] text-wrap text-justify ">
                    {p.description}
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TeamInfo;
