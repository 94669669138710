// import React from 'react'

import Footer from "../../components/common/Footer";

const Shipping = () => {
  return (
<>
<main>
      <h1 className="header">Shipping Policy</h1>

      <div>
        <h1 className="header">Digital Courses</h1>
        <p className="  pre">
          All our courses are delivered digitally. Upon successful enrollment,
          you will receive an email with instructions on how to access the
          course materials.
        </p>
        <h1 className="header">Physical Materials</h1>
        <p className="  pre">
          If a course includes physical materials, they will be shipped within 7
          business days of enrollment. Shipping times may vary based on your
          location.
        </p>
        <h1 className="header">Shipping Costs</h1>
        <p className="  pre">
          Shipping costs for physical materials are included in the course fee
          unless otherwise specified.
        </p>
        <h1 className="header">Shipping Issues</h1>
        <p className="  pre">
          If you encounter any issues with the shipment of physical materials,
          please contact our support team for assistance.
        </p>
      </div>
    </main>
    <Footer/>
</>
  );
};

export default Shipping;
