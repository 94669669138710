import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    loading: false,
};
// console.log(initialState , "initialState in profile slice")

const profileSlice = createSlice({
    name: "profile",
    initialState: initialState,
    reducers: {
        setUser(state, action) {
            // console.log("Setting user in profileSlice:", action.payload);
            if (action.payload && typeof action.payload === 'object') {
                // console.log(action.payload , "action.payload in profile slice ")
                state.user = action.payload;
                // console.log(state.user, "action.payload in profile slice ")
                localStorage.setItem("user", JSON.stringify(action.payload));
            } else {
                console.warn("Attempted to set user with invalid data:", action.payload);
            }
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const { setUser, setLoading } = profileSlice.actions;
export default profileSlice.reducer;