// import img1 from "../assets/Team/Himanshu_sir.png";
// import img2 from "../assets/Team/laxmi_mam.png";
// import img3 from "../assets/Team/akash_sir_hts.png";
// import img4 from "../assets/Team/nitish_sir.png";
// import img5 from "../assets/Team/Rudra_sir.png";
// import img6 from "../assets/Team/Tarun_Sir.png";
// import img7 from "../assets/Team/";
// import img8 from "../assets/Team/Wazid_sir.png";
// https://res.cloudinary.com/defdiimmj/image/upload/v1721650784/Hts/dbbktfi2mv9limc9d1sd.png

export const instructorDataArray = [
  {
    src: "https://res.cloudinary.com/defdiimmj/image/upload/v1721650784/Hts/zfuetm5vt4opm33f6wpy.png",
    name: "Himanshu Chauhan ",
    designation: "MERN Development Instructor",
    description:
      "Himanshu Chauhan is a seasoned Software Developer and a YouTuber, widely recognized for his expertise in Coding and Software Engineering. He has garnered a significant following among both students and working professionals in multinational companies. As a mentor, Himanshu has guided over 500 students, leveraging his MCA in Computer Applications and his professional experience at ex... Wipro, Blostem and others",
  },
  {
    src: "https://res.cloudinary.com/defdiimmj/image/upload/v1721650789/Hts/d672pbarfikjkcei2ou4.png",
    name: "Laxmi Chahar ",
    designation: "Quality Assurance (QA) Instructor",
    description : "Laxmi Chahar is a proficient Software Tester and a YouTuber, widely recognized for her expertise in performance Testing. She has established herself as a popular figure among both students and working professionals in multinational companies. Laxmi has mentored over 500 students, drawing from her B.Tech in Information Technology and her professional experience at NIC (National Informatics Centre)."
  },
  { src: "https://res.cloudinary.com/defdiimmj/image/upload/v1721650785/Hts/sktdeco4d4m3dfflu7qb.png", name: "Akash Tyagi ", designation: "Java Development Instructor" , 
    description : "Akash Tyagi is a skilled Java Developer and a YouTuber, widely recognized for his expertise in Coding and Software Engineering. He has become a popular figure among both students and working professionals in multinational companies. Akash has mentored over 500 students, drawing on his B.Tech in Information Technology and his professional experience at NIC."
   },
  { src: 'https://res.cloudinary.com/defdiimmj/image/upload/v1721650784/Hts/xhnfn5td2yjqxvnugu6c.png', name: "Nitish Saini", designation: "Automation Testing Instructor" ,
    description : "Nitesh is a dedicated Software Tester and a YouTuber, widely recognized for his Software Testing skills. He has become a popular figure among both students and working professionals in multinational companies. With experience mentoring over 500 students, Nitesh brings valuable insights from his MCA in Computer Applications and his professional tenure at TCS."
  },
  {
    src: "https://res.cloudinary.com/defdiimmj/image/upload/v1721650784/Hts/zrmf653uxitpzzhqexpv.png",
    name: "Rudra Pratap Singh ",
    designation: "Placement Assistance Instructor",
    description : "Rudra Pratap Singh is a highly skilled Placement Instructor, renowned for his expertise in Coding and Software Engineering. His reputation has grown significantly among students and professionals in multinational companies. With a B.Tech in Information Technology and professional experience at Wipro, Rudra has successfully mentored over 500 students, helping them achieve their career goals."
  },
  {
    src: "https://res.cloudinary.com/defdiimmj/image/upload/v1721650785/Hts/rush1kmej9rnxvsr2ngt.png",
    name: "Tarun Chauhan ",
    designation: "Placement Assistance Instructor ",
    description : "Tarun Chauhan is a proficient Software Test Engineer, widely recognized for his Software Testing skills. He has established himself as a popular figure among both students and working professionals in multinational companies. With experience mentoring over 500 students, Tarun brings valuable insights from his B.Tech in Information Technology and his professional tenure at Meritto."
  },
  // { src: img7, name: "Vishvas Chhiroliya ", designation: "SEO Specialist" ,
  //   description : "Vishwas is a seasoned SEO Specialist with a proven track record of driving organic traffic and improving search engine rankings. Leveraging his in-depth knowledge of SEO strategies, keyword research, and content optimization, he has successfully enhanced the online visibility of numerous businesses. With experience in both technical and on-page SEO, he has helped companies achieve higher rankings and increased web traffic. Vishwas dedication to staying updated with the latest SEO trends and algorithms ensures that his clients remain competitive in the ever-evolving digital landscape."
  // },
  {
    src: "https://res.cloudinary.com/defdiimmj/image/upload/v1721650785/Hts/lnydbqxmrvkumopha0dw.png",
    name: "Wazid Khan ",
    designation: "Performance Testing Instructor",
    description : "Wazid Khan is a proficient Software Tester and a YouTuber, widely recognized for his Software Testing skills. He has established himself as a popular figure among both students and working professionals in multinational companies. With experience mentoring over 500 students, Wazid draws on his M.Tech in Information Technology and his professional tenure at TCS."
  },
];
