// import React from "react";
import Footer from "../../components/common/Footer";
import "./style.css";

const Privacy = () => {
  return (
    <>
      <main>
        <h1 className="header">Privacy Policy</h1>

        <div className="text-white">
          <h1 className="header">Introduction</h1>
          <p className="pre" >
            HTS LEARN is committed to protecting your privacy. This Privacy
            Policy explains how we collect, use, disclose, and safeguard your
            information when you visit our website and use our services.
          </p>
          <h1 className="header">Information Collection</h1>
          <p className="pre" >
            We may collect personal information such as your name, email
            address, phone number, and payment information when you enroll in
            our courses.
          </p>
          <h1 className="header">Use of Information</h1>
          <p className="pre" >
            We use the information collected to provide, operate, and maintain
            our services, process transactions, and communicate with you about
            your enrollment and course progress.
          </p>
          <h1 className="header">Disclosure of Information</h1>
          <p className="pre" >
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties except when required by law or as
            necessary to provide our services.
          </p>
          <h1 className="header">Security</h1>
          <p className="pre" >
            We implement various security measures to protect your personal
            information. However, no method of transmission over the Internet or
            method of electronic storage is 100% secure.
          </p>
          <h1 className="header">Changes to Privacy Policy</h1>
          <p className="pre" >
            We may update our Privacy Policy from time to time. Any changes will
            be posted on this page with an updated revision date.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Privacy;
