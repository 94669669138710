export const FooterLink2 = [
  {
    title: "Courses We Offer ",
    links: [
      { title: "Front-End Development", link: "/catalog/web-development" },
      { title: "Back-End Development", link: "/catalog/web-development" },
      { title: "MERN Web Development", link: "/catalog/web-development" },
      { title: "Java Web Development", link: "/catalog/web-development" },
      { title: "Manual + Automation", link: "/catalog/software-testing" },
      { title: "Digital Marketing", link: "/catalog/digital-marketing" },
    ],
  },
  {

    title: "Languages",
    links: [
      { title: "HTML & CSS", link: "/html-css" },
      { title: "JavaScript", link: "/javascript" },
      { title: "React", link: "/react" },
      { title: "Java", link: "/java" },
    ],
  },
  {
    title: "Career Paths",
    links: [
      
      { title: "Front-End Developer  ", link: "/frontendCareerpath" },
      { title: "Back-End Developer", link: "/backendCareerpath" },
      { title: "MERN Stack  Developer", link: "/mernStackCareerpath" },
      { title: "Java Full Stack Developer", link: "/javaDevelopmentCareerpath" },
      { title: "Manual + Automation", link: "/manualautomationCareerpath" },
    ],
  },
];
