// APP.js

import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/common/Navbar";
import OpenRoute from "./components/core/Auth/OpenRoute";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import UpdatePassword from "./pages/UpdatePassword";
import VerifyEmail from "./pages/VerifyEmail";
import About from "./pages/About";
import Contact from "./pages/Contact";
import MyProfile from "./components/core/Dashboard/MyProfile";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import Error from "./pages/Error";
import Settings from "./components/core/Dashboard/Settings";
import { useSelector } from "react-redux";
import EnrolledCourses from "./components/core/Dashboard/EnrolledCourses";
import Cart from "./components/core/Dashboard/Cart";
import { ACCOUNT_TYPE } from "./utils/constants";
import AddCourse from "./components/core/Dashboard/AddCourse";
import MyCourses from "./components/core/Dashboard/MyCourses";
import EditCourse from "./components/core/Dashboard/EditCourse";
import Catalog from "./pages/Catalog";
import CourseDetails from "./pages/CourseDetails";
import ViewCourse from "./pages/ViewCourse";
import VideoDetails from "./components/core/ViewCourse/VideoDetails";
import Instructor from "./components/core/Dashboard/InstructorDashboard/Instructor";
import Terms from "./footerComponents/Polices/Terms";
import Privacy from "./footerComponents/Polices/Privacy";
import Refund from "./footerComponents/Polices/Refund";
import Cancellation from "./footerComponents/Polices/Cancellation";
import Shipping from "./footerComponents/Polices/Shipping";
import HelpCenter from "./footerComponents/Support/HelpCenter";
import OurInstructor from "./components/common/OurInstructor";
import { useEffect, useState } from "react";
import HtmlCss from "./footerComponents/Languages/HtmlCss";
import JavaScript from "./footerComponents/Languages/JavaScript";
import React from "./footerComponents/Languages/React";
import Java from "./footerComponents/Languages/Java";
import Frontendweb from "./footerComponents/CareerPaths/Frontendweb";
import BackendWeb from "./footerComponents/CareerPaths/BackendWeb";
import Mernweb from "./footerComponents/CareerPaths/Mernweb";
import JavaWeb from "./footerComponents/CareerPaths/JavaWeb";
import ManualAutomation from "./footerComponents/CareerPaths/ManualAutomation";

import ProjectIdeas from "./footerComponents/Resources/ProjectIdeas";
import PaymentStatus from "./components/core/ViewCourse/PaymentStatus";
import Whatsapp from "./components/ContactPage/Whatsapp";
// import Cookie from "./footerLinks/Cookie";

function App() {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { user } = useSelector((state) => state.profile);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 30);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-screen min-h-screen bg-richblack-900 flex flex-col font-inter">
      {isLoading ? (
        <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-black">
          <video muted loop autoPlay className="w-full h-full">
            <source
              src="https://res.cloudinary.com/dptbfc7bc/video/upload/v1721481475/tup949huipur8xussdfm.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />

            <Route
              path="payment-status"
              element={<PaymentStatus />}
            />

            {/*  Footer Routes Start  */}
            {/*  Footer Privacy Routes Start  */}
            <Route path="/term-and-conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/refund-policy" element={<Refund />} />
            <Route path="/cancellation-policy" element={<Cancellation />} />
            <Route path="/shipping-policy" element={<Shipping />} />
            <Route path="/help-center" element={<HelpCenter />} />

            <Route path="catalog/:catalogName" element={<Catalog />} />
            <Route path="courses/:courseId" element={<CourseDetails />} />
            {/*  Footer Privacy Routes END  */}

            {/*  Footer Language Routes Start  */}
            <Route path="/html-css" element={<HtmlCss />} />
            <Route path="/javascript" element={<JavaScript />} />
            <Route path="/react" element={<React />} />
            <Route path="/java" element={<Java />} />

            {/*  Footer Language Routes END  */}

            {/*  Footer Career Path Routes START  */}
            <Route path="/frontendCareerpath" element={<Frontendweb />} />
            <Route path="/backendCareerpath" element={<BackendWeb />} />
            <Route path="/mernStackCareerpath" element={<Mernweb />} />
            <Route path="/javaDevelopmentCareerpath" element={<JavaWeb />} />
            <Route
              path="/manualautomationCareerpath"
              element={<ManualAutomation />}
            />

            {/*  Footer Career Path Routes END  */}
            {/*  Footer Resources  Routes START  */}
            <Route path="projects" element={<ProjectIdeas />} />

            {/*  Footer Resources  Routes END  */}

            {/*  Footer Routes End  */}
 
            <Route
              path="signup"
              element={
                <OpenRoute>
                  <Signup />
                </OpenRoute>
              }
            />
            <Route
              path="login"
              element={
                <OpenRoute>
                  <Login />
                </OpenRoute>
              }
            />

            <Route
              path="forgot-password"
              element={
                <OpenRoute>
                  <ForgotPassword />
                </OpenRoute>
              }
            />

            <Route
              path="verify-email"
              element={
                <OpenRoute>
                  <VerifyEmail />
                </OpenRoute>
              }
            />

            <Route
              path="update-password/:token"
              element={
                <OpenRoute>
                  <UpdatePassword />
                </OpenRoute>
              }
            />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/our-team" element={<OurInstructor />} />

            <Route
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              <Route path="dashboard/my-profile" element={<MyProfile />} />

              <Route path="dashboard/Settings" element={<Settings />} />

              {user?.accountType === ACCOUNT_TYPE.STUDENT && (
                <>
                  <Route path="dashboard/cart" element={<Cart />} />
                  <Route
                    path="dashboard/enrolled-courses"
                    element={<EnrolledCourses />}
                  />
                </>
              )}

              {user?.accountType === ACCOUNT_TYPE.INSTRUCTOR && (
                <>
                  <Route path="dashboard/instructor" element={<Instructor />} />
                  <Route path="dashboard/add-course" element={<AddCourse />} />
                  <Route path="dashboard/my-courses" element={<MyCourses />} />
                  <Route
                    path="dashboard/edit-course/:courseId"
                    element={<EditCourse />}
                  />
                </>
              )}
            </Route>

            <Route
              element={
                <PrivateRoute>
                  <ViewCourse />
                </PrivateRoute>
              }
            >
              {user?.accountType === ACCOUNT_TYPE.STUDENT && (
                <>
                  <Route
                    path="view-course/:courseId/section/:sectionId/sub-section/:subSectionId"
                    element={<VideoDetails />}
                  />
                </>
              )}
            </Route>

            <Route path="*" element={<Error />} />

            <Route path="/enquiry" element={<Whatsapp />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;

// .spinner {
//   width: 56px;
//   height: 56px;
//   border-radius: 50%;
//   align-self: center;
//   margin-top: 2rem;
//   background: conic-gradient(#0000 10%,#ffffff);
//   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
//   animation: spinner-zp9dbg 1s infinite linear;
// }

// @keyframes spinner-zp9dbg {
//   to {
//      transform: rotate(1turn);
//   }
// }
// .ctaButton{
//    box-shadow: -2px -2px 0px 0px rgba(255, 255, 255, 0.18) inset;
// }
