import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentStatus = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get('transactionId');

  useEffect(() => {
    if (transactionId) {
      // Fetch payment status from your backend API
      fetch(`https://hts-core-s4w1.onrender.com/v1/payment/payment-status/${transactionId}`)
        .then((response) => response.json())
        .then((data) => {
          setPaymentStatus(data.status);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching payment status:', error);
          setLoading(false);
        });
    }
  }, [transactionId]);

  useEffect(() => {
    if (paymentStatus === 'SUCCESS') {
      // Redirect to enrolled courses after successful payment
      navigate('/dashboard/enrolled-courses');
    } else if (paymentStatus === 'FAILED') {
      // Redirect to payment retry page or show error message
      navigate('/payment-retry');
    }
  }, [paymentStatus, navigate]);

  if (loading) {
    return <div>Loading payment status...</div>;
  }

  return (
    <div>
      <h1>Payment Status</h1>
      {paymentStatus === 'SUCCESS' ? (
        <p>Your payment was successful! Redirecting to your courses...</p>
      ) : paymentStatus === 'FAILED' ? (
        <p>Payment failed. Redirecting to retry...</p>
      ) : (
        <p>Payment status: {paymentStatus}</p>
      )}
    </div>
  );
};

export default PaymentStatus;
