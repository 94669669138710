// import React from 'react'
import Footer from "../../components/common/Footer";
import "../footerStyle.css";

const HtmlCss = () => {
  return (
    <>
        <div className="main">
      <h2 className="heading">HTML (HyperText Markup Language):</h2>
      <p>
        HTML is the standard markup language used to create the structure and content of web pages. It consists of a series of elements (tags) which define the different parts of a document. These elements are represented by opening and closing tags, enclosing content to describe its meaning and function.
      </p>
      <p>
        HTML elements can include headings, paragraphs, links, images, forms, tables, and more. Attributes within tags provide additional information or functionality. For example, the <code>&lt;a&gt;</code> tag for links can include an <code>href</code> attribute specifying the URL.
      </p>
      <p>Example:</p>
      <pre>
        {`
<!DOCTYPE html>
<html>
<head>
  <title>Page Title</title>
</head>
<body>

<h1>This is a Heading</h1>
<p>This is a paragraph.</p>

<a href="https://example.com">Visit Example</a>

</body>
</html>
        `}
      </pre>
      
      <h2>CSS (Cascading Style Sheets):</h2>
      <p>
        CSS is a stylesheet language used to control the presentation (the look and formatting) of HTML documents. It allows web designers and developers to style elements such as colors, fonts, spacing, layout, and more. CSS works by targeting HTML elements and applying style rules to them.
      </p>
      <p>
        CSS can be applied using selectors that target specific elements, classes, or IDs. Properties within these rules define how elements should appear. CSS can also manage responsive design, transitions, and animations.
      </p>
      <p>Example:</p>
      <pre>
        {`
/* CSS Stylesheet */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

h1 {
  color: #333333;
  text-align: center;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
        `}
      </pre>
      
      <p>
        CSS can be applied to HTML documents in various ways, including inline styles, internal styles within &lt;style&gt; tags in the &lt;head&gt; section, or external stylesheets linked to the HTML document.
      </p>
      
      <p>
        Together, HTML and CSS form the backbone of web design and development, separating content (HTML) from presentation (CSS) to create visually appealing and structured web pages.
      </p>
    </div>

    <Footer />
    </>
  )
}

export default HtmlCss