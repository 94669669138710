export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Catalog",
    // path: '/catalog',
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
  {
    title: "Our Team",
    path: "/our-team",
  },
  {
    title: "Enquiry",
    path: "/enquiry",
  },
];
