// import React from "react";

import Footer from "../../components/common/Footer";

const Cancellation = () => {
  return (
   <>
     <main>
      <h1 className="header">Cancellation Policy</h1>
      <div>
        <h1 className="header">Course Cancellation by Student</h1>
        <p className="  pre">
          Students may cancel their enrollment up to 7 days before the course
          start date to receive a full refund. Cancellations made less than 7
          days before the course start date will not be eligible for a refund.
        </p>
        <h1 className="header">Course Cancellation by HTS LEARN</h1>
        <p className="  pre">
          HTS LEARN reserves the right to cancel any course due to insufficient
          enrollment or unforeseen circumstances. In such cases, a full refund
          will be issued.
        </p>
      </div>
    </main>
    <Footer/>
   </>
  );
};

export default Cancellation;
