// import React from "react";


import Footer from "../../components/common/Footer";
import "./style.css";

const Refund = () => {
  return (
    <>
      <main>
        <h1 className="header">Refund and Return Policy</h1>

        <div>
          <h1 className="header">Refund Eligibility</h1>
          <p className="  pre">
            Refund requests must be made within 14 days of course enrollment.
            Refunds will only be granted if you have not accessed more than 10%
            of the course materials.
          </p>
          <h1 className="header">Refund Process</h1>
          <p className="  pre">
            To request a refund, please contact our support team with your
            enrollment details. Refund will be processed within 14 business days
            of approval and credited to your source account in 15-16 business
            days.
          </p>
          <h1 className="header">Non-Refundable Items</h1>
          <p className="  pre">
            Any course materials that have been accessed or downloaded are
            non-refundable.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Refund;
