//SmallScreen.jsx

import { useEffect, useState } from "react";
// import { AiOutlineShoppingCart } from "react-icons/ai"
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
// import * as Icons from "react-icons/vsc"
import { logout } from "../../services/operations/authAPI";
// import { VscSignOut } from "react-icons/vsc"

// import logo from "../../assets/Logo/Logo-Full-Light.png"
import { NavbarLinks } from "../../data/navbar-links";
import { sidebarLinks } from "../../data/dashboard-links";
import { apiConnector } from "../../services/apiconnector";
import { categories } from "../../services/apis";
import { ACCOUNT_TYPE } from "../../utils/constants";
import ConfirmationModal from "./ConfirmationModal";
// import ProfileDropdown from "../core/Auth/ProfileDropDown"

// import {ImCross} from "react-icons/im"
export default function SmallScreenNavbar({ handleCrossButton }) {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { totalItems } = useSelector((state) => state.cart);
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subLinks, setSubLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);

  // const windowWidth = useRef(window.innerWidth);
  // console.log("--------window-width----", windowWidth);
  // const [smallScreen, setSmallScreen] = useState(false);
  // const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await apiConnector("GET", categories.CATEGORIES_API);
        setSubLinks(res.data.data);
      } catch (error) {
        console.log("Could not fetch Categories.", error);
      }
      setLoading(false);
    })();
  }, []);

  // console.log("sub links", subLinks)

  const matchRoute = (route) => {
    return matchPath({ path: route }, location.pathname);
  };

  // const handleCrossButton = () => {
  //   isClose = isClose ? setIsClose(false) : setIsClose(true);
  //   // smallScreen = smallScreen ? setSmallScreen(false) : setSmallScreen(true);
  // }
  // const Icon = Icons[iconName]

  return (
    <div
      className={`moving-div-left 
      flex flex-col fixed right-0 top-[57px] h-screen  justify-center border-l-[1px] z-[1000]
      border-l-richblack-700 w-[40%] ${
        location.pathname !== "/" ? "bg-richblack-800" : "bg-richblack-900"
      } transition-all duration-200`}
    >
      {/* ${isClose ? "moving-div-left" : "moving-div-right"} */}
      <div
        className={`flex flex-col  ${
          location.pathname !== "/" ? "bg-richblack-800" : "bg-richblack-900"
        }  relative  w-[100%] h-[95%] 
         items-center gap-5`}
      >
        {/* Login / Signup / Dashboard */}
        {token === null && (
          <div className="items-center gap-y-2  flex flex-col">
            <div className="flex gap-4">
              {user && user?.accountType !== ACCOUNT_TYPE.INSTRUCTOR && (
                <Link to="/dashboard/cart" className="relative">
                  {/* <AiOutlineShoppingCart className="text-2xl text-richblack-100" /> */}
                  {totalItems > 0 && (
                    <span
                      className="absolute -bottom-2 -right-2 grid h-5 w-5 place-items-center overflow-hidden 
                rounded-full bg-richblack-600 text-center text-xs font-bold text-yellow-100"
                    >
                      {totalItems}
                    </span>
                  )}
                </Link>
              )}
              {/* {token !== null && <ProfileDropdown />} */}
            </div>

            {token === null && (
              <Link to="/login">
                <button
                  className="rounded-[8px] border border-richblack-700 bg-richblack-800 px-[6px] py-[4px]  z-[1000]   text-richblack-100"
                  onClick={handleCrossButton}
                >
                  Log in
                </button>
              </Link>
            )}
            {token === null && (
              <Link to="/signup">
                <button
                  className="rounded-[8px] border border-richblack-700 bg-richblack-800
              z-[1000] px-[6px] py-[4px] text-richblack-100"
                  onClick={handleCrossButton}
                >
                  Sign up
                </button>
              </Link>
            )}
          </div>
        )}

        {/* DASHBOARD LINKS */}
        <nav className="block  ">
          <ul className="flex flex-col text-richblack-25  ">
            {sidebarLinks.map((link) => (
              /* const Icon = Icons[{link?.icon}]; */
              <>
                {link.name === "My Profile" && token !== null && (
                  <li key={link?.id} className="">
                    <Link to={link?.path}>
                      <p
                        className={`${
                          matchRoute(link?.path)
                            ? "text-yellow-25"
                            : "text-richblack-25"
                        }`}
                      >
                        {link?.name}
                      </p>
                    </Link>
                  </li>
                )}
                {token !== null && link?.type === user?.accountType && (
                  <li key={link?.id}>
                    <Link to={link?.path}>
                      <div
                        className={`  ${
                          link.name === "Enrolled Courses " ? "-mt-6" : ""
                        }`}
                      >
                        <p
                          className={`${
                            matchRoute(link?.path)
                              ? "text-yellow-25"
                              : "text-richblack-25"
                          }`}
                        >
                          {link?.name}
                        </p>
                      </div>
                    </Link>
                  </li>
                )}
              </>
            ))}
            <div
              className={` ${
                token !== null && user?.accountType === ACCOUNT_TYPE.STUDENT
                  ? "mt-3"
                  : "mt-2"
              }
           w-[90%]  h-[1px] bg-richblack-700 `}
            ></div>
            {token !== null && (
              <>
                <Link to="/dashboard/settings">
                  <button onClick={handleCrossButton}>
                    <div>
                      {/* //icon  */}
                      {/* {link.icon} */}
                      {/* <Icon />  */}
                      <p
                        className={` ${
                          matchRoute("/dashboard/settings")
                            ? "text-yellow-25"
                            : "text-richblack-25"
                        }`}
                      >
                        Settings
                      </p>
                    </div>
                  </button>
                </Link>
                <button
                  onClick={() =>
                    setConfirmationModal({
                      text1: "Are you sure?",
                      text2: "You will be logged out of your account.",
                      btn1Text: "Logout",
                      btn2Text: "Cancel",
                      btn1Handler: () => dispatch(logout(navigate)),
                      btn2Handler: () => setConfirmationModal(null),
                    })
                  }
                  className={` ${
                    token !== null && user?.accountType === ACCOUNT_TYPE.STUDENT
                      ? "mr-16"
                      : "mr-8"
                  }`}
                >
                  {/* <div className="flex items-center gap-x-2"> */}
                  {/* <VscSignOut className="text-lg" /> */}
                  {/* <span> */}
                  Logout
                  {/* </span> */}
                  {/* </div> */}
                </button>
              </>
            )}
          </ul>
        </nav>

        {token !== null && (
          <div className="w-[100%] h-[1px] bg-richblack-700 "></div>
        )}

        {/* Navigation links */}
        <nav className="block">
          <ul className="flex flex-col lg:gap-y-3 text-richblack-25">
            {NavbarLinks.map((link, index) => (
              <li key={index}>
                {link.title === "Catalog" ? (
                  <>
                    <div
                      className={`group relative flex cursor-pointer items-center gap-1 ${
                        matchRoute("/catalog/:catalogName")
                          ? "text-yellow-25"
                          : "text-richblack-25"
                      }`}
                    >
                      <p>{link.title}</p>
                      <BsChevronDown />
                      <div
                        className="invisible absolute left-[50%] top-[50%] z-[1000] flex w-[100px] 
                      translate-x-[-50%] translate-y-[3em] flex-col rounded-lg bg-richblack-5 p-2
                      text-richblack-900 opacity-0 transition-all duration-150 group-hover:visible 
                      group-hover:translate-y-[1.65em] group-hover:opacity-100 lg:w-[300px]"
                      >
                        <div
                          className="absolute left-[50%] top-0 -z-10 h-6 w-6 translate-x-[80%]
                          translate-y-[-40%] rotate-45 select-none rounded bg-richblack-5"
                        ></div>
                        {loading ? (
                          <p className="text-center spinner">Loading...</p>
                        ) : subLinks.length ? (
                          <>
                            {subLinks
                              ?.filter(
                                (subLink) => subLink?.courses?.length > 0
                              )
                              ?.map((subLink, i) => (
                                <Link
                                  to={`/catalog/${subLink.name
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}`}
                                  className="rounded-lg bg-transparent py-2 pl-2 hover:bg-richblack-50"
                                  key={i}
                                >
                                  <p>{subLink.name}</p>
                                </Link>
                              ))}
                          </>
                        ) : (
                          <p className="text-center">No Course Found</p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <Link to={link?.path}>
                    <p
                      className={`${
                        matchRoute(link?.path)
                          ? "text-yellow-25"
                          : "text-richblack-25"
                      }`}
                    >
                      {link.title}
                    </p>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
    </div>
  );
}

// export default SmallScreenNavbar
