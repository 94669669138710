// import React from "react";
import Footer from "../../components/common/Footer";
import "../footerStyle.css";

const ProjectIdeas = () => {
  return (
   <>
    <div className="main">
      <h2 className="heading">Basic Project Ideas for Web Development</h2>

      <h3 className="text-[2rem] mb-6 mt-20">HTML and CSS Projects:</h3>
      <ol>
        <li>
          <h3 className="subHeading">Personal Portfolio Website:</h3> Showcase your skills,
          projects, and contact information using HTML for structure and CSS for
          styling.
        </li>
        <li>
          <h3 className="subHeading">Recipe Book:</h3> Create a website where users can browse
          recipes categorized by type. Use HTML for structure and CSS for
          layout.
        </li>
        <li>
          <h3 className="subHeading">Product Landing Page:</h3> Design a landing page for a
          product or service. Implement responsiveness using CSS media queries.
        </li>
      </ol>

      <h3 className="text-[2rem] mb-6 mt-20">JavaScript (Vanilla JS) Projects:</h3>
      <ol start={4}>
        <li>
          <h3 className="subHeading">To-Do List Application:</h3> Develop a simple to-do list
          where users can add, edit, and delete tasks. Use JavaScript for
          functionality and local storage.
        </li>
        <li>
          <h3 className="subHeading">Weather Application:</h3> Build an app that fetches
          weather data from an API and displays current conditions based on user
          input.
        </li>
        <li>
          <h3 className="subHeading">Interactive Image Gallery:</h3> Create a gallery where
          users can view and navigate through images. Use JavaScript for image
          handling and transitions.
        </li>
      </ol>

      <h3 className="text-[2rem] mb-6 mt-20">React Projects:</h3>
      <ol start={7}>
        <li>
          <h3 className="subHeading">Movie Search Application:</h3> Develop an app using the
          OMDB API to search for movies, view details, and save favorites. Use
          React for UI and state management.
        </li>
        <li>
          <h3 className="subHeading">E-commerce Website:</h3> Build a basic online store with
          product listings, shopping cart, and checkout functionality. Use React
          for frontend and mock APIs.
        </li>
        <li>
          <h3 className="subHeading">Blog with CMS:</h3> Create a blogging platform with
          features like user authentication, CRUD operations for posts, and
          comments. Use React and a backend API.
        </li>
      </ol>

      <h3 className="text-[2rem] mb-6 mt-20">MERN Stack Projects:</h3>
      <ol start={10}>
        <li>
          <h3 className="subHeading">Social Media Platform:</h3> Build a full-stack social
          media platform with user profiles, posts, comments, and likes. Use
          MongoDB, Express, React, and Node.js.
        </li>
        <li>
          <h3 className="subHeading">Real-time Chat Application:</h3> Create a chat app with
          real-time messaging using MongoDB, Express, React, Node.js, and
          Socket.io for communication.
        </li>
        <li>
          <h3 className="subHeading">Task Management System:</h3> Develop a system for managing
          projects, tasks, deadlines, and progress tracking. Use MongoDB,
          Express, React, and Node.js.
        </li>
      </ol>

      <h3 className="text-[2rem] mb-6 mt-20">Additional Project Ideas:</h3>
      <ul>
        <li>
          <h3 className="subHeading">Quiz Application:</h3> Create a quiz app with
          multiple-choice questions, score tracking, and leaderboard using React
          and a backend for data storage.
        </li>
        <li>
          <h3 className="subHeading">Fitness Tracker:</h3> Build an app to track workouts,
          goals, and progress with charts. Use React for frontend and MongoDB
          for data storage.
        </li>
        <li>
          <h3 className="subHeading">Job Board:</h3> Develop a platform for posting job
          listings and job applications. Use React, Express, MongoDB, and
          authentication.
        </li>
      </ul>

      <p>
        These project ideas cover a range of complexities and technologies,
        suitable for learning and practicing various aspects of web development.
      </p>
    </div>

    <Footer />

   </>
  );
};

export default ProjectIdeas;
